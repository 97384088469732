import React from "react";
import {Box, IconButton, List, ListItem, ListItemText, Stack} from "@mui/material";
import {CheckCircle, Delete, PlayArrow, Stop} from "@mui/icons-material";
import axios from "axios";
import {API_URL} from "../../../config";
import {firestore} from "../../../firebase/clientApp";

export const ListaMessaggi = ({messages, stato, refetch}) => {
    var docRef = firestore.collection(`test/data/messages`)
    var docRefMessage = firestore.collection(`test/`)

    const approve = async (id) => {
        await axios.post(`${API_URL}message/${id}/approve`)
            .finally(() => refetch())
    }

    const block = async (id) => {
        await axios.post(`${API_URL}message/${id}/block`)
            .finally(() => refetch())
    }

    async function handlePlayMessage(id) {
        if(id) {
            docRefMessage.doc('message').set({
                message: ''
            }).then(async () => {
                await docRef.doc(id).get().then((doc) => {
                    if (doc.exists) {
                        console.log("handlePlayMessage --> id:", doc.data()?.message)
                        docRefMessage.doc('message').set({
                            message: doc.data()?.message
                        })
                            .then(() => {
                                console.log("Document successfully written!");
                            })
                            .catch((error) => {
                                console.error("Error writing document: ", error);
                            });

                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });
            }).catch((error) => {
                console.error("Error writing document: ", error);
            });
        } else {
            docRefMessage.doc('message').set({
                message: ''
            }).then(() => {
                    console.log("Document successfully written!");
            }).catch((error) => {
                    console.error("Error writing document: ", error);
            });
        }
    }

    return(
      <Box>
          <List>
              {messages.map((m,i) =>
                  <ListItem key={stato+i} sx={{borderBottom:'1px solid rgba(255, 255, 255, 0.3)'}} secondaryAction={
                      <Stack direction={"row"} spacing={2}>
                          {(stato==='daApprovare' || stato ==='rifiutati') && <IconButton onClick={() => approve(m.id)} size={"large"}>
                              <CheckCircle fontSize={'inherit'}/>
                          </IconButton>}
                          {(stato==='daApprovare' || stato==='approvati') && <IconButton onClick={() => block(m.id)} size={"large"}>
                              <Delete fontSize={'inherit'}/>
                          </IconButton>}
                          {<IconButton onClick={() => handlePlayMessage(m.id)} size={"large"}>
                              <PlayArrow fontSize={'inherit'}/>
                          </IconButton>}
                          {<IconButton onClick={() => handlePlayMessage('')} size={"large"}>
                              <Stop fontSize={'inherit'}/>
                          </IconButton>}
                      </Stack>
                  }>
                      <ListItemText>
                          {m.message}
                      </ListItemText>
                  </ListItem>
              )}
          </List>
      </Box>
  )
}
