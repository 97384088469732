import React, {useEffect} from "react";
import {ListaMessaggi} from "./ListaMessaggi";
import useAxios from "axios-hooks";
import {API_URL} from "../../../config";
import {Box, CircularProgress} from "@mui/material";

export const MessaggiApprovati = () => {
    const [{data, loading, error},refetch] = useAxios(`${API_URL}message/approved`)

    useEffect(() => {
        refetch()
    },[])

  return(
      <Box>
          {loading ? <CircularProgress/> : <ListaMessaggi stato={'approvati'} messages={data} refetch={refetch}/>}
      </Box>
  )
}
