import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";

export const FotoWallContent = ({g, rows, columns}) => {

    const [grid, setGrid] = useState([])
    useEffect(() => {
        setGrid(g)
    },[g])

  return(
      <Box id={'gallery'} style={{gridTemplateColumns: 'repeat(' + columns + ', 1fr)', gridTemplateRows: 'repeat(' + rows + ', 1fr)'}}>
          {
              grid.map((image, i) => {
                  return (<div key={i} style={{backgroundPosition:'center',backgroundImage: `url(https://storage.googleapis.com/bea-2022/${image.filename})`}}></div>)
              })
          }
      </Box>
  )
}
