import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Box, Button, Container, Stack, TextField} from "@mui/material";
import logo from '../assets/images/logo.png'
import {auth} from "../firebase/clientApp";

export const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate()

    const logIn = async (e) => {
        e.preventDefault()
        await auth.signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                navigate('/control/messaggi', {replace: true})
            })
            .catch((error) => {
                let errorCode = error.code;
                let errorMessage = error.message;
            });
    }

    return (
        <Container maxWidth={"sm"} style={{textAlign:"center"}}>
            <img src={logo} style={{width: 'min(100%, 20rem)', padding: '0.5rem'}}/>
            <form onSubmit={logIn}>
                <Stack direction={'column'} spacing={3}>
                    <TextField required placeholder={'Email'} onChange={(e) => setEmail(e.target.value)} type={"email"}/>
                    <TextField required placeholder={'Password'} onChange={(e) => setPassword(e.target.value)}
                               type={"password"}/>
                    <Box sx={{width: '100%', textAlign: 'center'}}>
                        <Button type={'submit'} variant={"contained"}>login</Button>
                    </Box>
                </Stack>
            </form>
        </Container>
    )
}
