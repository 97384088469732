import React from "react";
import {ListaMessaggi} from "./ListaMessaggi";
import {Box, CircularProgress} from "@mui/material";

export const MessaggiDaApprovare = ({data, loading, refetch}) => {

    return (
        <Box>
            {loading ? <CircularProgress/> : <ListaMessaggi stato={'daApprovare'} messages={data} refetch={refetch}/>}
        </Box>
    )
}
